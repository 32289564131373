<template>
  <div class="p-0 dashboard" :class="{ 'container-dashboard': dashboardLoadingCount }">
    <div class="row">
<!--      <div class="col-lg-12">-->
<!--                      <div class="row pl-1 mb-2">-->
<!--          <div class="filters__multiple">-->
<!--            <div class="input-group input-group-sm filters__input">-->
<!--                  <span class="input-group-addon filters__field-icon"><i-->
<!--                    class="fa fa-map-marker"></i></span>-->
<!--              <select @change="refreshDashboard"-->
<!--                      class="form-control form-control-sm filters__field"-->
<!--                      v-model="filters.location">-->
<!--                <option :selected="true" :value="'All locations'">{{-->
<!--                  $t('general.allLocations')-->
<!--                  }}-->
<!--                </option>-->
<!--                <option v-for="location in locationsList" :value="location.id"-->
<!--                        :key="location.id">-->
<!--                  {{ location.name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div-->
<!--            class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--            <div-->
<!--              class="  d-flex align-items-end justify-content-start"-->
<!--            >-->
<!--              <Switch-component-->
<!--                :disabled="this.filters.location === 'All locations'"-->
<!--                v-model="filters.with_childs"-->
<!--                class="switcher-with-childs-for-use-in-filters"-->
<!--                @change="refreshDashboard"-->
<!--              />-->
<!--            </div>-->
<!--            <div-->
<!--              class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--              <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="col-lg-3 input-group-for-location-select-in-PortalDashborad--filters mb-h">

                                                                         <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-PortalDashborad--filters"
                    enable-max-option-width
                    small-size
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                           :disabled="filters.location === 'All locations'"
                          @input="refreshDashboard"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
      </div>


    </div>
    <div class="row">
            <div class="col-lg-3">
                        <div class="filters__multiple">
                  <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
                    <button
                      v-if="isFiltersActive"
                      class="btn-outline-secondary btn btn-sm  new-modern-style-btn-in-filters mt-0"
                      @click.stop.prevent="resetAllFilters"
                    >
                      <i class="fa fa-close"></i>
                      {{ $t('general.resetAllFilters') }}
                    </button>
                  </div>
                </div>
      </div>
    </div>

    <div>
      <!--      <div v-if="dashboardLoadingCount || isMarketerDashboardLoading" class="loader loader-dashboard"></div>-->
      <!--      <div v-if="dashboardLoadingCount || isMarketerDashboardLoading"><Loader-spinner centered></Loader-spinner></div>-->
      <!--      <div v-if="!dashboardLoadingCount && !isMarketerDashboardLoading" class="animated fadeIn">-->
      <div class="animated fadeIn">
        <!-- <div class="d-flex flex-wrap justify-content-around"> -->
        <masonry :cols="{ default: 4, 1700: 4, 1400: 3, 1000: 2, 800: 1 }" :gutter="{ default: '2rem', 760: '1rem' }">
          <!-- Connections-->
          <div role="button" @click="goToPortalStatistics" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.connections"
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.connections" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img
                    class="img-in-card---connections-block"
                    src="/static/img/portal-dashboard/connections-block.png"
                  />
                </div>
                <h4 class="mb-1">{{ $t('portalDashboard.connections') }}</h4>
                <div class="portal-dashboard-card-caption">{{ $t('portalDashboard.connectionsPeriod') }}</div>
                <div class="portal-dashboard-card-content" v-if="totalConnectionsForMarketerDashBoard > 0">
                  <div>
                    <div>
                      <div class="card-portal-dashboard-inner-wrapper">
                        <div class="d-flex flex-wrap justify-content-around mt-2">
                          <div class="portal-dashboard-digit-accent-connections-wrapper">
                            <div class="portal-dashboard-digit-accent-connections-digit">
                              <span>{{ uniqClientsForMarketerDashBoard || 0 }}</span>
                              <numbers-difference
                                :difference="this.uniqClientsForMarketerDashBoardDifference"
                              ></numbers-difference>
                            </div>
                            <div class="portal-dashboard-digit-accent-connections-caption">
                              <strong>{{ $t('portalDashboard.connectionsUnique') }}</strong>
                            </div>
                          </div>
                          <div class="portal-dashboard-digit-accent-connections-wrapper">
                            <div class="portal-dashboard-digit-accent-connections-digit">
                              <span>{{ totalConnectionsForMarketerDashBoard || 0 }}</span>
                              <numbers-difference
                                :difference="this.totalConnectionsForMarketerDashBoardDifference"
                              ></numbers-difference>
                            </div>
                            <div class="portal-dashboard-digit-accent-connections-caption">
                              <strong>{{ $t('portalDashboard.connectionsCaption') }}</strong>
                            </div>
                          </div>
                          <div class="portal-dashboard-digit-accent-connections-wrapper">
                            <div class="portal-dashboard-digit-accent-connections-digit">
                              <span>{{ newUsersForMarketerDashBoard || 0 }}</span>
                              <numbers-difference
                                :difference="this.newUsersForMarketerDashBoardDifference"
                              ></numbers-difference>
                            </div>
                            <div class="portal-dashboard-digit-accent-connections-caption">
                              <strong>{{ $t('portalDashboard.connectionsNew') }}</strong>
                            </div>
                          </div>
                          <div class="portal-dashboard-digit-accent-connections-wrapper">
                            <div class="portal-dashboard-digit-accent-connections-digit">
                              <span>{{ notNewUsersForMarketerDashBoard || 0 }}</span>
                              <numbers-difference
                                :difference="this.notNewUsersForMarketerDashBoardDifference"
                              ></numbers-difference>
                            </div>
                            <div class="portal-dashboard-digit-accent-connections-caption">
                              <strong>{{ $t('portalDashboard.connectionsRepeat') }}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="portal-dashboard-card-content" v-else>
                  <div class="card-portal-dashboard-inner-wrapper">
                    <div class="text-center">
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!--AuthN-->
          <div role="button" @click="goToPortalStatistics" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.authN"
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---authentications-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.authN" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img
                    class="img-in-card---authentications-block"
                    src="/static/img/portal-dashboard/authentications-block.png"
                  />
                </div>
                <h4 class="mb-1">{{ $t('portalDashboard.authentications') }}</h4>
                <div class="portal-dashboard-card-caption">{{ $t('portalDashboard.authenticationsPeriod') }}</div>
                <div
                  class="portal-dashboard-card-content"
                  v-if="
                    dataForAuthNCardFromPieEndpoint.types &&
                    Object.keys(dataForAuthNCardFromPieEndpoint.types).length > 0
                  "
                >
                  <div>
                    <div>
                      <h5>
                        <strong>{{ $t('portalDashboard.authenticationsTotal') }}:</strong>
                        {{ dataForAuthNCardFromPieEndpoint.total || 0 }}
                        <numbers-difference
                          :hide-percents="true"
                          :difference="dataDifferenceForAuthNCardFromPieEndpoint.total"
                        ></numbers-difference>
                      </h5>
                      <h6 v-if="smsCostForRange !== undefined">
                        <strong>{{ $t('portalDashboard.smsCost') }}:</strong>
                        {{ smsCostForRange }} {{ $t('general.rub') }}
                        <span class="numbers-difference-for-smsCost">
                          <numbers-difference
                            showEqualIfDifferenceDirectionIsNoneAndHidePercentsEnabled
                            v-if="smsCostForPrevRange !== undefined"
                            :hide-percents="true"
                            :difference="dataDifferenceForAuthNCardSMSCost"
                          ></numbers-difference>
                        </span>
                      </h6>
                      <div class="card-portal-dashboard-inner-wrapper">
                        <div class="d-flex flex-wrap justify-content-around mt-2">
                          <div
                            v-for="(counter, type, index) in dataForAuthNCardFromPieEndpoint.types"
                            v-if="index < 5"
                            class="portal-dashboard-digit-accent-wrapper"
                          >
                            <div class="portal-dashboard-digit-accent-digit">
                              <span :class="{ 'portal-dashboard-digit-accent-digit-small': counter && counter > 999 }">
                                {{ counter || 0 }}
                              </span>
                              <numbers-difference
                                :difference="dataDifferenceForAuthNCardFromPieEndpoint.types[type]"
                              ></numbers-difference>
                            </div>
                            <div class="portal-dashboard-digit-accent-caption">
                              <strong>{{ type }}</strong>
                            </div>
                          </div>
                          <div
                            v-if="Object.keys(dataForAuthNCardFromPieEndpoint.types).length > 5"
                            class="portal-dashboard-digit-accent-wrapper-and-more"
                          >
                            <div class="portal-dashboard-digit-accent-digit">
                              ...
                            </div>
                            <div class="portal-dashboard-digit-accent-caption-and-more">
                              <strong>{{ $t('portalDashboard.showMore') }}</strong>
                            </div>
                          </div>
                          <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
                          <div
                            v-if="Object.keys(dataForAuthNCardFromPieEndpoint.types).length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                          <div
                            v-if="Object.keys(dataForAuthNCardFromPieEndpoint.types).length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                          <div
                            v-if="Object.keys(dataForAuthNCardFromPieEndpoint.types).length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                        </div>
                      </div>
                      <p class="mb-1 card-systemtext"></p>
                    </div>
                  </div>
                </div>
                <div class="portal-dashboard-card-content" v-else>
                  <div class="card-portal-dashboard-inner-wrapper">
                    <div class="text-center">
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!--AuthZ-->
          <div role="button" @click="goToPortalStatistics" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.authZ"
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---authorizations-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.authZ" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img
                    class="img-in-card---authorizations-block"
                    src="/static/img/portal-dashboard/authorizations-block.png"
                  />
                </div>
                <h4 class="mb-1">{{ $t('portalDashboard.authorizations') }}</h4>
                <div class="portal-dashboard-card-caption">{{ $t('portalDashboard.authorizationsPeriod') }}</div>
                <div
                  class="portal-dashboard-card-content"
                  v-if="
                    dataForAuthZCardFromPieEndpoint.types &&
                    Object.keys(dataForAuthZCardFromPieEndpoint.types).length > 0
                  "
                >
                  <div>
                    <div>
                      <h5>
                        <strong>{{ $t('portalDashboard.authenticationsTotal') }}:</strong>
                        {{ dataForAuthZCardFromPieEndpoint.total || 0 }}
                        <numbers-difference
                          :hide-percents="true"
                          :difference="dataDifferenceForAuthZCardFromPieEndpoint.total"
                        ></numbers-difference>
                      </h5>
                      <div class="card-portal-dashboard-inner-wrapper">
                        <div class="d-flex flex-wrap justify-content-around mt-2">
                          <div
                            v-for="(counter, type, index) in dataForAuthZCardFromPieEndpoint.types"
                            v-if="index < 5"
                            class="portal-dashboard-digit-accent-wrapper"
                          >
                            <div class="portal-dashboard-digit-accent-digit">
                              <span :class="{ 'portal-dashboard-digit-accent-digit-small': counter && counter > 999 }">
                                {{ counter || 0 }}
                              </span>
                              <numbers-difference
                                :difference="dataDifferenceForAuthZCardFromPieEndpoint.types[type]"
                              ></numbers-difference>
                            </div>
                            <div class="portal-dashboard-digit-accent-caption">
                              <strong>{{ type }}</strong>
                            </div>
                          </div>
                          <div
                            v-if="Object.keys(dataForAuthZCardFromPieEndpoint.types).length > 5"
                            class="portal-dashboard-digit-accent-wrapper-and-more"
                          >
                            <div class="portal-dashboard-digit-accent-digit">
                              ...
                            </div>
                            <div class="portal-dashboard-digit-accent-caption-and-more">
                              <strong>{{ $t('portalDashboard.showMore') }}</strong>
                            </div>
                          </div>
                          <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
                          <div
                            v-if="Object.keys(dataForAuthZCardFromPieEndpoint.types).length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                          <div
                            v-if="Object.keys(dataForAuthZCardFromPieEndpoint.types).length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                          <div
                            v-if="Object.keys(dataForAuthZCardFromPieEndpoint.types).length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                        </div>
                      </div>

                      <p class="mb-1 card-systemtext"></p>
                    </div>
                  </div>
                </div>
                <div class="portal-dashboard-card-content" v-else>
                  <div class="card-portal-dashboard-inner-wrapper">
                    <div class="text-center">
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- Poll recomendation-->
          <div role="button" @click="goToAdsNew" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.pollRecomendation"
              class="card card-icon-portal-dashboard card-portal-dashboard card-icon---poll-recomend-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.pollRecomendation" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img
                    class="img-in-card---poll-recomend-block"
                    src="/static/img/portal-dashboard/poll-recomend-block.png"
                  />
                </div>
                <h4 class="mb-2">{{ $t('portalDashboard.recomendation') }}</h4>
                <p class="mb-1 card-systemtext">
                  <br />
                </p>
                <div class="card-portal-dashboard-inner-wrapper">
                  <recommendation-card locale-key="addPolls" link="/portal/ads">
                    <template v-slot:icon>
                      <span class="icon">
                        <i class="fa fa-list" aria-hidden="true"></i>
                      </span>
                    </template>
                  </recommendation-card>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- Locations -->
          <div role="button" @click="goToLocations" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.locations"
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---locations-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.locations" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img class="img-in-card---locations-block" src="/static/img/portal-dashboard/locations-block.png" />
                </div>
                <h4 class="mb-1">{{ $t('portalDashboard.locations') }}</h4>
                <div class="portal-dashboard-card-content" v-if="allLocations.length > 0">
                  <div>
                    <div>
                      <div v-if="allLocations.length > 0">
                        <h5>
                          <strong>
                            <router-link
                              v-if="$can('read', { route: 'Location' })"
                              class="ml-auto one-line-text in-card-portal-dashboard"
                              to="/settings/location"
                            >
                              {{ $t('portalDashboard.locationsTotal') }}: {{ allLocations.length || 0 }}
                            </router-link>
                          </strong>
                        </h5>
                      </div>
                      <p class="mb-1 card-systemtext"></p>
                      <div class="text-center">
                        <h5 class="mt-1">{{ $t('portalDashboard.locationsWhereCurrentUserisManager') }}:</h5>
                      </div>
                      <div v-if="allLocationsForCurrentUser.length > 0">
                        <div class="card-portal-dashboard-inner-wrapper">
                          <div class="d-flex align-items-center">
                            <div class="d-flex justify-content-center locations-when-manager-left-block">
                              <div class="portal-dashboard-digit-accent-locations-when-manager-wrapper">
                                <div class="portal-dashboard-digit-accent-locations-when-manager-digit">
                                  {{ allLocationsForCurrentUser.length || 0 }}
                                </div>
                                <div class="portal-dashboard-digit-accent-locations-when-manager-caption">
                                  <strong>{{ $t('portalDashboard.locationsWhenManager') }}</strong>
                                </div>
                              </div>
                            </div>
                            <div
                              class="locations-when-manager-list d-flex justify-content-center locations-when-manager-right-block"
                            >
                              <div>
                                <ul
                                  v-for="(location, index) in allLocationsForCurrentUser"
                                  :key="location.id"
                                  v-if="index < 6"
                                  class="mb-0 card-systemtext ul-in-card-portal-dashboard"
                                >
                                  <li class="li-in-card-portal-dashboard">
                                    <router-link
                                      v-if="$can('read', { route: 'Location' })"
                                      class="ml-auto one-line-text in-card-portal-dashboard"
                                      :to="{ name: 'Location', params: { locationId: location.id } }"
                                    >
                                      {{ location.name | cutLocationName }}
                                    </router-link>
                                  </li>
                                </ul>
                                <ul
                                  class="mb-0 card-systemtext ul-in-card-portal-dashboard"
                                  v-if="allLocationsForCurrentUser.length > 6"
                                >
                                  <li class="li-in-card-portal-dashboard">...{{ $t('portalDashboard.showMore') }}</li>
                                </ul>
                              </div>
                            </div>
                            <p class="mb-1 card-systemtext"></p>
                          </div>
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="card-portal-dashboard-inner-wrapper">
                          {{ $t('portalDashboard.noLocations') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="portal-dashboard-card-content" v-else>
                  <div class="card-portal-dashboard-inner-wrapper">
                    <div class="text-center">
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- Locations map-->
          <div class="card-portal-dashboard-wrapper" v-if="isShowMapsAndLatitudeLongitudeSettings">
            <div
              v-if="!showSpinner.locationsMap"
              class="card card-portal-dashboard card-icon-portal-dashboard loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.locationsMap" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img class="img-in-card---locations-block" src="/static/img/portal-dashboard/locations-block.png" />
                </div>
                <h4 class="mb-1">
                  <span @click="goToLocations" role="button">{{ $t('portalDashboard.locationsMap') }}</span>
                  &nbsp;
                  <i @click="toggleShowMapInModal" role="button" class="fa fa-expand" aria-hidden="true"></i>
                </h4>
                <div class="card-portal-dashboard-inner-wrapper" v-if="allLocations.length > 0">
                  <map-for-locations
                    :showOnlyNameInTooltip="true"
                    @markerClick="onLocationsMapMarkerClick"
                    :locationsWithCoords="locationsWithCoords"
                  ></map-for-locations>
                  <p class="mb-1 card-systemtext"></p>
                </div>
                <div v-else>
                  <div class="card-portal-dashboard-inner-wrapper">
                    <div class="text-center">
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- Support block-->
          <div class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.supportBlock"
              class="card card-portal-dashboard support-block card-icon-portal-dashboard card-icon---support-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.supportBlock" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img class="img-in-card---support-block" src="/static/img/portal-dashboard/support-block.png" />
                </div>
                <h4 class="mb-1">{{ $t('portalDashboard.support') }}</h4>
                <div class="card-portal-dashboard-inner-wrapper">
                  <div class="text-center">
                    <p class="mb-1 card-systemtext">
                      {{ $t('portalDashboard.supportText') }}
                    </p>
                    <p class="mb-1 card-systemtext">
                      <a class="in-card-portal-dashboard" :href="`mailto:${getContactEmail()}`">
                        {{ getContactEmail() }}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- Birthday Recomendations-->
          <div role="button" @click="goToAdsNew" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.birthdayRecomendations"
              class="card card-portal-dashboard card-portal-dashboard-recomendation-birthdays loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.birthdayRecomendations" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <h4 class="mb-1">{{ $t('portalDashboard.recomendationVKFB') }}</h4>
                <p class="mb-1 card-systemtext">
                  <br />
                </p>
                <div class="card-portal-dashboard-inner-wrapper">
                  <recommendation-card locale-key="addPollsUserDataBirthday" link="/portal/ads">
                    <template v-slot:icon>
                      <small class="icon">
                        <i class="fa fa-birthday-cake" aria-hidden="true"></i>
                      </small>
                    </template>
                  </recommendation-card>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- Birthdays -->
          <div role="button" @click="goToPortalBirthdays" class="card-portal-dashboard-wrapper">
            <div
              v-show="!showSpinner.birthdays"
              class="card card-portal-dashboard portal-dashboard-calendar-wrapper card-portal-dashboard-birthdays-block card-icon-portal-dashboard card-icon---birthdays-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.birthdays" centered></Loader-spinner-small>-->
              <!--              <div class="card-block pb-0" :key="birthdaysKeyForRerender">-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img class="img-in-card---birthdays-block" src="/static/img/portal-dashboard/birthdays-block.png" />
                </div>
                <h4 @click="calendarClick" role="button" class="mb-1">{{ $t('portalDashboard.birthdays') }}</h4>
                <p class="mb-1 card-systemtext">
                  <br />
                </p>
                <div class="portal-dashboard-calendar" v-if="showBirthdaysBlock">
                  <PortalBirthdaysForPortalDashboard
                    :location="this.filters.location"
                    :with_childs="this.filters.with_childs"
                    @birthDaysLoaded="birthDaysLoadedHandler"
                    @birthDaysLoading="birthDaysLoadingHandler"
                  ></PortalBirthdaysForPortalDashboard>
                </div>
              </div>
            </div>
            <div
              v-show="showSpinner.birthdays"
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- OS statistics-->
          <div role="button" @click="goToPortalStatistics" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.osStatistics"
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---os-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.osStatistics" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img class="img-in-card---os-block" src="/static/img/portal-dashboard/os-block.png" />
                </div>
                <h4 class="mb-1">{{ $t('portalDashboard.os') }}</h4>
                <div class="portal-dashboard-card-caption">{{ $t('portalDashboard.osPeriod') }}</div>
                <div
                  class="portal-dashboard-card-content"
                  v-if="
                    dataForOSCardFromPieEndpoint.types && Object.keys(dataForOSCardFromPieEndpoint.types).length > 0
                  "
                >
                  <div>
                    <div>
                      <h5>
                        <strong>{{ $t('portalDashboard.authenticationsTotal') }}:</strong>
                        {{ dataForOSCardFromPieEndpoint.total || 0 }}
                      </h5>
                      <div class="card-portal-dashboard-inner-wrapper">
                        <div class="d-flex flex-wrap justify-content-around mt-1">
                          <div
                            v-for="(counter, type, index) in dataForOSCardFromPieEndpoint.types"
                            v-if="index < 5"
                            class="portal-dashboard-digit-accent-wrapper"
                          >
                            <div class="portal-dashboard-digit-accent-digit">
                              {{ counter || 0 }}
                            </div>
                            <div class="portal-dashboard-digit-accent-caption">
                              <strong>{{ type }}</strong>
                            </div>
                          </div>
                          <div
                            v-if="Object.keys(dataForOSCardFromPieEndpoint.types).length > 5"
                            class="portal-dashboard-digit-accent-wrapper-and-more"
                          >
                            <div class="portal-dashboard-digit-accent-digit">
                              ...
                            </div>
                            <div class="portal-dashboard-digit-accent-caption-and-more">
                              <strong>{{ $t('portalDashboard.showMore') }}</strong>
                            </div>
                          </div>
                          <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
                          <div
                            v-if="Object.keys(dataForOSCardFromPieEndpoint.types).length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                          <div
                            v-if="Object.keys(dataForOSCardFromPieEndpoint.types).length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                          <div
                            v-if="Object.keys(dataForOSCardFromPieEndpoint.types).length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                        </div>
                      </div>

                      <p class="mb-1 card-systemtext"></p>
                    </div>
                  </div>
                </div>
                <div class="portal-dashboard-card-content" v-else>
                  <div class="card-portal-dashboard-inner-wrapper">
                    <div class="text-center">
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- ADS statistics-->
          <div role="button" @click="goToAdsNewFromAdsBlock" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.adsStatistics"
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---ads-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.adsStatistics" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img class="img-in-card---ads-block" src="/static/img/portal-dashboard/ads-block.png" />
                </div>
                <h4 class="mb-1">{{ $t('portalDashboard.advertisement') }}</h4>
                <div class="portal-dashboard-card-caption">{{ $t('portalDashboard.adsPeriod') }}</div>
                <div class="portal-dashboard-card-content" v-if="adsForMarketerDashboard.length > 0">
                  <div>
                    <div>
                      <div class="mt-1" v-if="adsForMarketerDashboard.length > 0">
                        <h5>
                          <strong>
                            <router-link
                              v-if="$can('read', { route: 'PortalAdsNew' })"
                              class="ml-auto one-line-text in-card-portal-dashboard"
                              to="/portal/ads"
                            >
                              {{ $t('portalDashboard.advertisementTotal') }}:
                              {{ adsDataForMarketerDashboard.length || 0 }}
                            </router-link>
                          </strong>
                        </h5>
                        <div class="text-center mt-1">
                          <span>
                            {{ $t('portalDashboard.advertisementTop5') }} ({{
                              $t('portalDashboard.advertisementPeriod')
                            }}):
                          </span>
                        </div>
                        <div class="card-portal-dashboard-inner-wrapper-for-ads">
                          <div class="d-flex flex-wrap justify-content-around">
                            <div
                              v-for="ad in adsForMarketerDashboard"
                              :key="ad.id"
                              class="portal-dashboard-digit-accent-wrapper"
                            >
                              <div class="portal-dashboard-digit-accent-digit">
                                <span>
                                  {{ ad.counter }}
                                </span>
                              </div>
                              <div class="portal-dashboard-digit-accent-caption">
                                <router-link
                                  v-if="$can('read', { route: 'PortalAdsNew' })"
                                  class="ml-auto one-line-text in-card-portal-dashboard"
                                  :to="{ name: 'PortalAdsNew', params: { adId: ad.id, adName: ad.name } }"
                                >
                                  {{ ad.nameForShow }}
                                </router-link>
                              </div>
                            </div>
                            <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
                            <div
                              v-if="adsForMarketerDashboard.length > 3"
                              class="portal-dashboard-digit-accent-wrapper-hidden"
                            ></div>
                            <div
                              v-if="adsForMarketerDashboard.length > 3"
                              class="portal-dashboard-digit-accent-wrapper-hidden"
                            ></div>
                            <div
                              v-if="adsForMarketerDashboard.length > 3"
                              class="portal-dashboard-digit-accent-wrapper-hidden"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <p class="mb-1 card-systemtext"></p>

                      <p class="mb-1 card-systemtext"></p>
                    </div>
                  </div>
                </div>
                <div class="portal-dashboard-card-content" v-else>
                  <div class="card-portal-dashboard-inner-wrapper">
                    <div class="text-center">
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- Ads Views Statistic by dates-->
          <div role="button" @click="goToPortalAdsStatsFromAdsBlock" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.adsViewsStatistic"
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---ads-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.adsViewsStatistic" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img class="img-in-card---ads-block" src="/static/img/portal-dashboard/ads-block.png" />
                </div>
                <component :is="currentLocale === 'en' ? 'h4' : 'h5'" class="mb-1">
                  {{ $t('portalDashboard.advertisementStatistic') }}
                  {{ $t('portalDashboard.advertisementStatisticByDates') }}
                </component>
                <div class="portal-dashboard-card-caption">{{ $t('portalDashboard.adsViewsPeriod') }}</div>
                <div class="portal-dashboard-card-content" v-if="adsViewsMarketerDashboard.daysData.length > 0">
                  <div>
                    <p class="mb-1 card-systemtext"></p>
                    <div v-if="adsViewsMarketerDashboard.daysData.length > 0">
                      <b>
                        <h5 style="font-weight: 700; margin-top: -10px;" class="mb-2">
                          {{ $t('portalDashboard.advertisementTotal') }}:
                          {{ adsViewsMarketerDashboard.allcounter || 0 }}
                          {{ $t('portalDashboard.advertisementStatisticPeriod') }}
                        </h5>
                      </b>
                      <div class="text-center" style="margin-top: -17px;">
                        <span class="mt-1">{{ $t('portalDashboard.advertisementdays') }}:</span>
                      </div>

                      <div class="card-portal-dashboard-inner-wrapper-for-ads-views">
                        <div class="d-flex flex-wrap justify-content-around">
                          <div
                            v-for="(adViews, index) in adsViewsMarketerDashboard.daysData"
                            :key="index"
                            v-if="index < 6"
                            class="portal-dashboard-digit-accent-wrapper"
                          >
                            <div class="portal-dashboard-digit-accent-digit">
                              <span>
                                {{ adViews[1] }}
                              </span>
                            </div>
                            <div class="portal-dashboard-digit-accent-caption">
                              <router-link
                                v-if="$can('read', { route: 'PortalAdsNew' })"
                                class="ml-auto one-line-text in-card-portal-dashboard"
                                :to="{ name: 'PortalAdsNew', params: { start: adViews[0] } }"
                              >
                                {{ moment(adViews[0]).format(' DD.MM.YYYY') }}
                              </router-link>
                            </div>
                          </div>
                          <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
                          <div
                            v-if="adsViewsMarketerDashboard.daysData.length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                          <div
                            v-if="adsViewsMarketerDashboard.daysData.length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                          <div
                            v-if="adsViewsMarketerDashboard.daysData.length > 3"
                            class="portal-dashboard-digit-accent-wrapper-hidden"
                          ></div>
                        </div>
                      </div>
                      <p class="mb-1 card-systemtext"></p>
                    </div>
                  </div>
                </div>
                <div class="portal-dashboard-card-content" v-else>
                  <div class="card-portal-dashboard-inner-wrapper custom-margin-top-for-no-data-in-ads-views">
                    <div class="text-center">
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
          <!-- Quality poll  recomendations-->
          <div role="button" @click="goToAdsNew" class="card-portal-dashboard-wrapper">
            <div
              v-if="!showSpinner.qualityPollRecomendations"
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---poll-quality-recomend-block loader-spinner-wrapper"
            >
              <!--              <Loader-spinner-small v-if="showSpinner.qualityPollRecomendations" centered></Loader-spinner-small>-->
              <div class="card-block pb-0">
                <div class="float-right">
                  <img
                    class="img-in-card---poll-quality-recomend-block"
                    src="/static/img/portal-dashboard/poll-quality-recomend-block.png"
                  />
                </div>
                <h4 class="mb-2">{{ $t('portalDashboard.recomendation') }}</h4>
                <p class="mb-1 card-systemtext">
                  <br />
                </p>
                <div class="card-portal-dashboard-inner-wrapper">
                  <recommendation-card locale-key="addPollsQuality" link="/portal/ads">
                    <template v-slot:icon>
                      <small class="icon">
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </small>
                    </template>
                  </recommendation-card>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-portal-dashboard card-icon-portal-dashboard card-icon---connections-block loader-spinner-wrapper"
            >
              <Loader-spinner-small centered></Loader-spinner-small>
            </div>
          </div>
        </masonry>
        <!-- далее хак чтобы последняя строчка flex была выравнена по левому краю, вместо justify-content-around-->
        <!-- Если при justify-content-around в последней строке остается 2 карточки например, или 1 это смотрится странно-->
        <!-- <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div>
          <div class="card-portal-dashboard-wrapper card-portal-dashboard-wrapper-hidden"></div> -->
      </div>
    </div>
    <modal v-model="showMapInModal" class="modal-info" effect="fade/zoom" :backdrop="false" xxlarge>
      <h4 slot="modal-title" class="modal-title">
        {{ $t('portalDashboard.locationsMap') }}
      </h4>
      <div v-if="allLocations.length">
        <div v-if="allLocations.length > 0">
          <map-for-locations
            v-if="showMapInModal"
            show-full-screen
            @markerClick="onLocationsMapMarkerClick"
            :locationsWithCoords="locationsWithCoords"
          ></map-for-locations>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" @click="showMapInModal = false">
          {{ $t('general.close') }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
// import Vue from 'vue';
// import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts/highstock';
import NumbersDifference from '../../components/UI/NumbersDifference.vue';
// import highchartsMore from 'highcharts/highcharts-more';
// import solidgauge from 'highcharts/modules/solid-gauge';
// import drilldown from 'highcharts/modules/drilldown';
// import noData from 'highcharts/modules/no-data-to-display';
import { eventsChart } from '../../charts/events';
import commonService from '../../services/commonService';
// import cpeService from '../../services/cpeService';
// import DashboardRecommendations from '../../components/Dashboard/dashboard-recommendations.vue';
import dashboardService from '../../services/dashboardService';
// import DashboardVisitors from '../../components/Dashboard/dashboard-visitors.vue';
// import eventsService from '../../services/eventsService';
// import FoldingCard from '../../components/Universal/folding-card.vue';
import helpers from '../../helpers';
import locationService from '../../services/locationService';
import MapForLocations from '../../components/Maps/MapForLocations.vue';
import portalAdsService from '../../services/portalAdsService';
import portalAdsStatsService from '../../services/portalAdsStatsService';
import portalStatsService from '../../services/portalStatsService';
// import ruleService from '../../services/ruleService';
// import statsCard from '../../components/Universal/stats-card.vue';
import usersService from '../../services/usersService';
import RecommendationCard from '../../components/Dashboard/recommendation-card.vue';
import Modal from '../../components/Modal.vue';
import PortalBirthdaysForPortalDashboard from './PortalBirthdaysForPortalDashboard.vue';
import router from '../../router';

import SwitchComponent from '../../components/Universal/Switch-component.vue';

import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

// drilldown(Highcharts);
// highchartsMore(Highcharts);
// solidgauge(Highcharts);
// noData(Highcharts);
//
// Vue.use(VueHighcharts, { Highcharts });
// Vue.use(require('vue-moment'));

export default {
  name: 'PortalDashboard',
  components: {
    // DashboardRecommendations,
    // DashboardVisitors,
    MapForLocations,
    // FoldingCard,
    // statsCard,
    RecommendationCard,
    Modal,
    PortalBirthdaysForPortalDashboard,
    NumbersDifference,
    SwitchComponent,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      filters: {
        location: 'All locations',
        with_childs: true,

      },
      showBirthdaysBlock: true,
      smsCostForRange: undefined,
      smsCostForPrevRange: undefined,
      birthdaysKeyForRerender: 0,
      showBirthdaysForRerender: true,
      birthdaysLoading: false,
      osChartPie: {
        series: [
          {
            data: []
          }
        ]
      },
      authChartPie: {
        series: [
          {
            data: []
          }
        ]
      },
      authenChartPie: {
        series: [
          {
            data: []
          }
        ]
      },
      authChartPieForPreviousPeriod: {
        series: [
          {
            data: []
          }
        ]
      },
      authenChartPieForPreviousPeriod: {
        series: [
          {
            data: []
          }
        ]
      },
      showMapInModal: false,
      authChartBar: {
        series: [],
        xAxis: {
          categories: [],
          crosshair: true
        }
      },
      authenChartBar: {
        series: [],
        xAxis: {
          categories: [],
          crosshair: true
        }
      },
      osChartBar: {
        series: [],
        xAxis: {
          categories: [],
          crosshair: true
        }
      },
      marketerDashboardBlockDisplaying: {
        locations: true,
        mapOfLocations: true,
        advertisement: true,
        advertisementStatistic: true,
        connectionsStatistic: true,
        locationsWhereManager: true
      },

      totalConnectionsForMarketerDashBoard: 0,
      uniqClientsForMarketerDashBoard: 0,
      newUsersForMarketerDashBoard: 0,
      notNewUsersForMarketerDashBoard: 0,
      totalConnectionsForMarketerDashBoardForPreviousPeriod: 0,
      uniqClientsForMarketerDashBoardForPreviousPeriod: 0,
      newUsersForMarketerDashBoardForPreviousPeriod: 0,
      notNewUsersForMarketerDashBoardForPreviousPeriod: 0,
      adsViewsDataForMarketerDasboard: {},
      adsDataForMarketerDashboard: [],
      adsStatisticForMarketerDashboard: [],
      window,
      eventsChart,
      systemInfo: {},
      cpuPercent: '',
      memPercent: '',
      cpuCharts: {
        chart: {
          type: 'solidgauge'
        },
        lang: {
          noData: ''
        },
        pane: {
          center: ['50%', '85%'],
          size: '130%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
            innerRadius: '75%',
            outerRadius: '100%',
            shape: 'arc',
            borderColor: 'transparent'
          }
        },

        title: null,

        tooltip: {
          enabled: false,
          valueSuffix: '%'
        },

        // the value axis

        plotOptions: {
          solidgauge: {
            innerRadius: '75%',
            dataLabels: {
              y: -45,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        yAxis: {
          min: 0,
          max: 100,
          stops: [
            [0.1, '#4dbd74'], // green
            [0.5, '#f8cb00'], // yellow
            [0.9, '#f86c6b'] // red
          ],
          lineWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            text: '',
            y: -55
          },
          labels: {
            y: 16,
            x: 1,
            distance: -10
          }
        },

        credits: {
          enabled: false
        },

        series: [
          {
            name: 'CPU load',
            data: [],
            dataLabels: {
              format:
                `<div style="text-align:center"><span style="font-size:25px;color:${
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                }">{y}</span><br/>` + '<span style="font-size:12px;color:silver">%</span></div>'
            }
          }
        ]
      },

      memoryCharts: {
        chart: {
          type: 'solidgauge'
        },
        lang: {
          noData: ''
        },
        pane: {
          center: ['50%', '85%'],
          size: '130%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
            innerRadius: '75%',
            outerRadius: '100%',
            shape: 'arc',
            borderColor: 'transparent'
          }
        },

        title: null,

        tooltip: {
          enabled: false,
          valueSuffix: '%'
        },
        plotOptions: {
          solidgauge: {
            innerRadius: '75%',
            dataLabels: {
              y: -45,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        yAxis: {
          min: 0,
          max: 100,
          stops: [
            [0.1, '#4dbd74'], // green
            [0.5, '#f8cb00'], // yellow
            [0.9, '#f86c6b'] // red
          ],
          lineWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            text: '',
            y: -55
          },
          labels: {
            y: 16,
            x: 1,
            distance: -10
          }
        },

        credits: {
          enabled: false
        },

        series: [
          {
            name: 'Memory used',
            data: [],
            dataLabels: {
              format:
                `<div style="text-align:center"><span style="font-size:25px;color:${
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                }">{y}</span><br/>` + '<span style="font-size:12px;color:silver">%</span></div>'
            }
          }
        ]
      },

      cpeConnectedPieCharts: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        legend: {
          verticalAlign: 'bottom',
          itemStyle: { color: '#BCC5D0', fontSize: '9px' },
          margin: 5,
          lineHeight: 1,
          padding: 5,
          y: 17
        },
        plotOptions: {
          pie: {
            size: '135%',
            allowPointSelect: true,
            cursor: 'pointer',
            color: '#ffffff',
            dataLabels: {
              softConnector: true,
              enabled: true,
              formatter() {
                if (this.y != 0) {
                  return this.y;
                }
              },
              distance: 1
            },
            showInLegend: true
          }
        },

        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.0f}</b>'
        },

        series: [
          {
            name: 'CPEs',
            colorByPoint: true,
            data: [
              {
                name: this.$t('dashboard.connected'),
                y: 0,
                color: '#4dbd74'
              },
              {
                name: this.$t('dashboard.notConnected'),
                y: 0,
                color: '#BCC5D0'
              }
            ]
          }
        ]
      },

      cpeConfigPieCharts: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        legend: {
          verticalAlign: 'bottom',
          itemStyle: { color: '#BCC5D0', fontSize: '9px' },
          margin: 5,
          lineHeight: 1,
          padding: 5,
          y: 17
        },
        plotOptions: {
          pie: {
            size: '135%',
            allowPointSelect: true,
            cursor: 'pointer',
            color: '#ffffff',
            className: 'pie-class',
            dataLabels: {
              enabled: true,
              distance: 1,
              formatter() {
                if (this.y) {
                  return this.y;
                }
              }
            },
            showInLegend: true
          }
        },

        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.0f}</b>'
        },

        series: [
          {
            name: 'CPEs by config status',
            colorByPoint: true,
            data: [
              {
                name: this.$t('dashboard.online'),
                y: 0,
                color: '#4dbd74'
              },
              {
                name: this.$t('dashboard.updating'),
                y: 0,
                color: '#63c2de'
              },
              {
                name: this.$t('dashboard.offline'),
                y: 0,
                color: '#BCC5D0'
              },
              {
                name: this.$t('dashboard.empty'),
                y: 0,
                color: '#eee'
              }
            ]
          }
        ]
      },

      clientsTypePieCharts: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        legend: {
          verticalAlign: 'top',
          itemStyle: { color: '#BCC5D0', fontSize: '9px' },
          margin: 10,
          lineHeight: 1
        },
        plotOptions: {
          pie: {
            size: '170%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },

        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.0f}</b>'
        },

        series: [
          {
            name: 'CPEs by connected status',
            colorByPoint: true,
            data: [
              {
                name: 'Mobile',
                y: false,
                color: '#f86c6b'
              },
              {
                name: 'Camera',
                y: false,
                color: '#63c2de'
              },
              {
                name: 'Other',
                y: false,
                color: '#f8cb00'
              }
            ]
          }
        ]
      }
    };
  },
  computed: {
    isShowMapsAndLatitudeLongitudeSettings() {
      return this.$store.getters.isShowMapsAndLatitudeLongitudeSettings;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    isFiltersActive() {
      return this.filters.location !== 'All locations';
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    locationsList() {
      return this.$store.getters.locationsListSorted;
    },
    isGetSMSCostForRangeRequestExecuting() {
      return this.$store.state.getSMSCostForRangeRequestExecuting;
    },
    isGetSMSCostForPrevRangeRequestExecuting() {
      return this.$store.state.getSMSCostForPrevRangeRequestExecuting;
    },
    showSpinner() {
      // отвечает за отображение спиннеров в блоках портального дашборда
      return {
        // Подключения
        connections:
          this.isDataLoading.newUsers ||
          this.isDataLoading.notNewUsers ||
          this.isDataLoading.uniqClients ||
          this.isDataLoading.totalConnections ||
          this.isDataLoading.newUsersForPreviousPeriod ||
          this.isDataLoading.notNewUsersForPreviousPeriod ||
          this.isDataLoading.uniqClientsForPreviousPeriod ||
          this.isDataLoading.totalConnectionsForPreviousPeriod, // есть

        // Идентификации
        authN:
          this.isDataLoading.dataForAuthenticationsStatistic ||
          this.isDataLoading.dataForAuthenticationsStatisticForPreviousPeriod ||
          this.isDataLoading.dataForSMSCostForRange ||
          this.isDataLoading.dataForSMSCostForPrevRange,

        // Авторизации
        authZ:
          this.isDataLoading.dataForAuthorizationsStatistic ||
          this.isDataLoading.dataForAuthorizationsStatisticForPreviousPeriod,

        // Рекомендация Добавления опросов
        pollRecomendation: this.isDataLoading.dataForAdsStatistics,

        // Локации
        locations: this.isDataLoading.dataForLocations,

        // Карта локаций
        locationsMap: this.isDataLoading.dataForLocations,

        // Поддержка
        supportBlock: false, // есть

        // Рекомендация запрос данных пользователя
        birthdayRecomendations: this.isDataLoading.dataForAdsStatistics,

        // Дни рождения
        birthdays: this.isDataLoading.dataForBirthdays,

        // Операционные системы
        osStatistics: this.isDataLoading.dataForOSStatistic,

        // Рекламы
        adsStatistics: this.isDataLoading.dataForAdsStatistics,

        // Просмотры рекламы по датам
        adsViewsStatistic: this.isDataLoading.dataForAdsViewsStatistic,

        // Рекомендация опросов качества
        qualityPollRecomendations: this.isDataLoading.dataForAdsStatistics
      };
    },
    isDataLoading() {
      // содержит данные о статусе готовности информации, необходимой для наполнения карточек дашборда
      // если true то данные еще не готовы
      return {
        // запросы с данными для счетичков пользователей и соединений (для карточки Подключения)
        newUsers: this.requestsStatusForPortalDashboard.pie.first_visit,
        notNewUsers: this.requestsStatusForPortalDashboard.pie.first_visit,
        uniqClients: this.requestsStatusForPortalDashboard.pie.unique_visit,
        totalConnections: this.requestsStatusForPortalDashboard.pie.all_visit,

        // запросы с данными для счетичков пользователей и соединений за предыдущий период (для карточки Подключения)
        newUsersForPreviousPeriod: this.requestsStatusForPortalDashboardForPreviousPeriod.pie.first_visit,
        notNewUsersForPreviousPeriod: this.requestsStatusForPortalDashboardForPreviousPeriod.pie.first_visit,
        uniqClientsForPreviousPeriod: this.requestsStatusForPortalDashboardForPreviousPeriod.pie.unique_visit,
        totalConnectionsForPreviousPeriod: this.requestsStatusForPortalDashboardForPreviousPeriod.pie.all_visit,

        // запросы по идентификациям за текущий и прошедиший периоды
        dataForAuthenticationsStatistic: this.requestsStatusForPortalDashboard.pie.authen,
        dataForAuthenticationsStatisticForPreviousPeriod: this.requestsStatusForPortalDashboardForPreviousPeriod.pie
          .authen,
        // запросы по авторизациям за текущий и прошедиший периоды
        dataForAuthorizationsStatistic: this.requestsStatusForPortalDashboard.pie.auth,
        dataForAuthorizationsStatisticForPreviousPeriod: this.requestsStatusForPortalDashboardForPreviousPeriod.pie
          .auth,
        // данные для карточек лоакций и карты локаций
        dataForLocations: this.isLoadingLocationsForMarketerDashboard,
        // состояние загрузки данных по дням рождения
        dataForBirthdays: this.birthdaysLoading,
        // запрос по статистике пользовательсих ОС
        dataForOSStatistic: this.requestsStatusForPortalDashboard.pie.os,
        // данные для отображения в карточе Реклама
        dataForAdsStatistics:
          this.isAdsInfoForMarketerDashboardLoading || this.isAdsStatInfoForMarketerDashboardLoading,
        // данные для отображения в карточке Просмотры рекламы
        dataForAdsViewsStatistic: this.isAdsViewsForDashboardLoading,
        // загрузка данных по стоимости СМС сообщений для СМС аутентификацийm
        dataForSMSCostForRange: this.isGetSMSCostForRangeRequestExecuting,
        dataForSMSCostForPrevRange: this.isGetSMSCostForPrevRangeRequestExecuting
      };
    },
    requestsStatusForPortalDashboard() {
      return this.$store.state.activeRequestsForPortalDashboardPage;
    },
    requestsStatusForPortalDashboardForPreviousPeriod() {
      return this.$store.state.activeRequestsForPortalDashboardPageForPreviousPeriod;
    },
    totalConnectionsForMarketerDashBoardDifference() {
      return this.compareStatsValues(
        this.totalConnectionsForMarketerDashBoard,
        this.totalConnectionsForMarketerDashBoardForPreviousPeriod
      );
    },
    uniqClientsForMarketerDashBoardDifference() {
      return this.compareStatsValues(
        this.uniqClientsForMarketerDashBoard,
        this.uniqClientsForMarketerDashBoardForPreviousPeriod
      );
    },
    newUsersForMarketerDashBoardDifference() {
      return this.compareStatsValues(
        this.newUsersForMarketerDashBoard,
        this.newUsersForMarketerDashBoardForPreviousPeriod
      );
    },
    notNewUsersForMarketerDashBoardDifference() {
      return this.compareStatsValues(
        this.notNewUsersForMarketerDashBoard,
        this.notNewUsersForMarketerDashBoardForPreviousPeriod
      );
    },
    dataDifferenceForAuthZCardFromPieEndpoint() {
      const result = {
        total: {},
        types: {}
      };
      if (
        this.dataForAuthZCardFromPieEndpoint.total > 0 ||
        Object.keys(this.dataForAuthZCardFromPieEndpoint.types).length > 0
      ) {
        result.total = this.compareStatsValues(
          this.dataForAuthZCardFromPieEndpoint.total,
          this.dataForAuthZCardFromPieEndpointForPreviousPeriod.total
        );
        for (const type in this.dataForAuthZCardFromPieEndpoint.types) {
          if (this.dataForAuthZCardFromPieEndpointForPreviousPeriod.types.hasOwnProperty(type)) {
            result.types[type] = this.compareStatsValues(
              this.dataForAuthZCardFromPieEndpoint.types[type],
              this.dataForAuthZCardFromPieEndpointForPreviousPeriod.types[type]
            );
          } else {
            result.types[type] = this.compareStatsValues(this.dataForAuthZCardFromPieEndpoint.types[type], 0);
          }
        }
      }
      return result;
    },
    dataDifferenceForAuthNCardFromPieEndpoint() {
      const result = {
        total: {},
        types: {}
      };
      if (
        this.dataForAuthNCardFromPieEndpoint.total > 0 ||
        Object.keys(this.dataForAuthNCardFromPieEndpoint.types).length > 0
      ) {
        result.total = this.compareStatsValues(
          this.dataForAuthNCardFromPieEndpoint.total,
          this.dataForAuthNCardFromPieEndpointForPreviousPeriod.total
        );
        for (const type in this.dataForAuthNCardFromPieEndpoint.types) {
          if (this.dataForAuthNCardFromPieEndpointForPreviousPeriod.types.hasOwnProperty(type)) {
            result.types[type] = this.compareStatsValues(
              this.dataForAuthNCardFromPieEndpoint.types[type],
              this.dataForAuthNCardFromPieEndpointForPreviousPeriod.types[type]
            );
          } else {
            result.types[type] = this.compareStatsValues(this.dataForAuthNCardFromPieEndpoint.types[type], 0);
          }
        }
      }
      return result;
    },
    dataDifferenceForAuthNCardSMSCost() {
      let result = {};
      result = this.compareStatsValues(this.smsCostForRange, this.smsCostForPrevRange);
      // console.log(result)
      return result;
    },
    currentLocale() {
      return this.$store.getters.currentLocale;
    },
    dataForOSCard() {
      const result = {
        total: 0,
        types: {}
      };
      let total = 0;
      for (const type of this.osChartBar.series) {
        let counter = 0;
        const { name } = type;
        if (name !== 'Average' && name !== 'Среднее значение') {
          for (const dateCounter of type.data) {
            counter += dateCounter;
            total += dateCounter;
          }
          result.types[name] = counter;
        }
      }
      result.total = total;
      return result;
    },
    dataForOSCardFromPieEndpoint() {
      const result = {
        total: 0,
        types: {}
      };
      let total = 0;
      for (const type of this.osChartPie.series[0].data) {
        const counter = 0;
        const { name } = type;
        result.types[name] = type.amount;
        total += type.amount;
      }
      result.total = total;
      return result;
    },
    dataForAuthNCard() {
      const result = {
        total: 0,
        types: {}
      };
      let total = 0;
      for (const type of this.authenChartBar.series) {
        let counter = 0;
        const { name } = type;
        if (name !== 'Average' && name !== 'Среднее значение') {
          for (const dateCounter of type.data) {
            counter += dateCounter;
            total += dateCounter;
          }
          result.types[name] = counter;
        }
      }
      result.total = total;
      return result;
    },
    dataForAuthNCardFromPieEndpoint() {
      const result = {
        total: 0,
        types: {}
      };
      let total = 0;
      for (const type of this.authenChartPie.series[0].data) {
        const counter = 0;
        const { name } = type;
        result.types[name] = type.amount;
        total += type.amount;
      }
      result.total = total;
      return result;
    },
    dataForAuthNCardFromPieEndpointForPreviousPeriod() {
      const result = {
        total: 0,
        types: {}
      };
      let total = 0;
      for (const type of this.authenChartPieForPreviousPeriod.series[0].data) {
        const counter = 0;
        const { name } = type;
        result.types[name] = type.amount;
        total += type.amount;
      }
      result.total = total;
      return result;
    },
    dataForAuthZCard() {
      const result = {
        total: 0,
        types: {}
      };
      let total = 0;
      for (const type of this.authChartBar.series) {
        let counter = 0;
        const { name } = type;
        if (name !== 'Average' && name !== 'Среднее значение') {
          for (const dateCounter of type.data) {
            counter += dateCounter;
            total += dateCounter;
          }
          result.types[name] = counter;
        }
      }
      result.total = total;
      return result;
    },
    dataForAuthZCardFromPieEndpoint() {
      const result = {
        total: 0,
        types: {}
      };
      let total = 0;
      for (const type of this.authChartPie.series[0].data) {
        const counter = 0;
        const { name } = type;
        result.types[name] = type.amount;
        total += type.amount;
      }
      result.total = total;
      return result;
    },
    dataForAuthZCardFromPieEndpointForPreviousPeriod() {
      const result = {
        total: 0,
        types: {}
      };
      let total = 0;
      for (const type of this.authChartPieForPreviousPeriod.series[0].data) {
        const counter = 0;
        const { name } = type;
        result.types[name] = type.amount;
        total += type.amount;
      }
      result.total = total;
      return result;
    },
    isConnectionsDataForMarketerDashBoard() {
      if (
        this.totalConnectionsForMarketerDashBoard === 0 &&
        this.uniqClientsForMarketerDashBoard === 0 &&
        this.newUsersForMarketerDashBoard === 0 &&
        this.notNewUsersForMarketerDashBoard === 0
      ) {
        return false;
      }
      return true;
    },
    isMarketerDashboardLoading() {
      return (
        this.isLoadingLocationsForMarketerDashboard ||
        this.isAdsInfoForMarketerDashboardLoading ||
        this.isAdsStatInfoForMarketerDashboardLoading ||
        this.isAdsViewsForDashboardLoading
      );
    },
    isLoadingLocationsForMarketerDashboard() {
      return this.$store.state.loadingLocations;
    },
    locationsWithCoords() {
      return this.locationsItems.filter((location) => {
        if (location.coords.lat || location.coords.lng) {
          return true;
        }
        return false;
      });
    },
    locationsWithCoordsForCurrentUser() {
      return this.locationsWithCoords.filter((location) => location.manager === this.$store.state.userData.id);
    },
    isPortalAdsLoading() {
      return this.$store.state.loadingPortalAds || this.$store.state.loadingPortalAdsStats;
    },
    isAdsInfoForMarketerDashboardLoading() {
      return this.$store.state.loadingAdsInfoForMarketerDashboard;
    },
    isAdsStatInfoForMarketerDashboardLoading() {
      return this.$store.state.loadingAdsStatsInfoForMarketerDashboard;
    },
    isAdsViewsForDashboardLoading() {
      return this.$store.state.loadingAdsViewsDataForMarketerDashboard;
    },
    adsForMarketerDashboard() {
      if (
        this.isAdsInfoForMarketerDashboardLoading === false &&
        this.isAdsStatInfoForMarketerDashboardLoading === false
      ) {
        const adsDataToDashboard = [];
        if (this.adsDataForMarketerDashboard && this.adsStatisticForMarketerDashboard) {
          const copy = JSON.parse(JSON.stringify(this.adsDataForMarketerDashboard));
          this.adsStatisticForMarketerDashboard.forEach((item) => {
            const ad = copy.find((ad) => ad.id === item.id);
            if (ad) {
              let shortedName;
              if (ad.name && ad.name.length > 16) {
                shortedName = `${ad.name.slice(0, 14)}...`;
              } else {
                shortedName = ad.name;
              }
              adsDataToDashboard.push({
                nameForShow: shortedName,
                name: ad.name,
                counter: item.counter,
                counter_skipped: item.counter_skipped,
                duration: item.duration,
                type: ad.data.type,
                id: ad.id
              });
            }
          });
          const sortedByViews = adsDataToDashboard.sort((a, b) => {
            if (a.counter > b.counter) {
              return -1;
            }
            if (a.counter < b.counter) {
              return 1;
            }
            return 0;
          });
          return sortedByViews.splice(0, 6);
        }
        return [];
      }
      return [];
    },
    adsViewsMarketerDashboard() {
      if (!this.adsViewsDataForMarketerDasboard.allforDashBoardStat) {
        return { allcounter: 0, daysData: [] };
      }
      if (this.isAdsViewsForDashboardLoading === false) {
        let counter = 0;
        for (const day of this.adsViewsDataForMarketerDasboard.allforDashBoardStat) {
          counter = day[1] + counter;
        }
        const unsortedViewsData = [...this.adsViewsDataForMarketerDasboard.allforDashBoardStat];
        const sortedByViewsData = unsortedViewsData.sort((a, b) => {
          if (a[1] > b[1]) {
            return -1;
          }
          if (a[1] < b[1]) {
            return 1;
          }
          return 0;
        });
        return { allcounter: counter, daysData: sortedByViewsData.splice(0, 6) };
      }
      return { allcounter: 0, daysData: [] };
    },
    usersList() {
      return this.$store.state.usersList || [];
    },
    locationsItems() {
      return this.$store.state.locationsList;
    },
    rootLocation() {
      return this.locationsItems.find((item) => this.isRootLocations(item.id));
    },
    nonRootLocations() {
      return this.locationsItems.filter((item) => !this.isRootLocations(item.id));
    },
    allLocations() {
      // old return this.locationsItems;
      // now with shuffle
      let all = [...this.locationsItems];
      all = this.shuffleArr(all);
      return all;
    },
    allLocationsForCurrentUser() {
      return this.allLocations.filter((location) => location.manager === this.$store.state.userData.id);
    },
    nonRootLocationsForCurrentUser() {
      return this.nonRootLocations.filter((location) => location.manager === this.$store.state.userData.id);
    },
    locationsCount() {
      return this.nonRootLocationsForCurrentUser.length;
    },
    isCurrentUserMarketer() {
      return this.$store.state.userData.role === 'marketer';
    },
    currentSystemInfo() {
      return this.$store.state.systemInfo;
    },
    cpesInfo() {
      return this.$store.state.cpesInfo;
    },
    clientsInfo() {
      return this.$store.state.clientsInfo;
    },
    isLoadingDashboardsCharts() {
      return this.$store.state.loadingDashboardsCharts;
    },
    isEmpty() {
      const { systemInfo } = this.$store.state;
      return Object.keys(systemInfo).length > 1;
    },
    dashboardLoadingCount() {
      return this.$store.state.dashboardLoadingCount;
    },
    // refreshInterval() {
    //   return this.$store.state.refreshInterval;
    // },
    dashboardInterval() {
      return this.$store.state.intervals.dashboard;
    }
  },
  methods: {
    refreshDashboard() {
      this.getAll()
      this.showBirthdaysBlock = false;
      this.$nextTick(() => {
        this.showBirthdaysBlock = true;
      })
    },
    getAll() {
      locationService.getLocations(this);
      usersService.getUsers(this, {onlyLoad: true});
      // portalAdsService.getPortalAdsForMarketerDashboard(this);
      portalAdsService.getPortalAdsForMarketerDashboard(this, {useLocationFilter: true});
      portalAdsService.getAdStatisticsForMarketerDashboard(this, {useLocationFilter: true});
      portalAdsStatsService.getRangeForMarketerDashBoard(this, {useLocationFilter: true});
      this.getConnectionsDataForMarketerDashboardForForPreviousPeriod([
        'all_visit',
        'unique_visit',
        'first_visit',
        'authen',
        'auth'
      ]);
      this.getConnectionsDataForMarketerDashboard(['all_visit', 'unique_visit', 'first_visit', 'authen', 'auth', 'os']);
      this.getSMSCostForRange();
      this.getSMSCostForPrevRange();
    },
    birthDaysLoadedHandler() {
      this.birthdaysLoading = false; // скрываем спинеры
    },
    birthDaysLoadingHandler() {
      this.birthdaysLoading = true; // показываем спинеры
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshDashboard()


    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters.location = 'All locations'
    //   this.filters.with_childs = true;
    //   this.refreshDashboard();
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      // this.filters.location = 'All locations'
      // this.filters.with_childs = true;
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = true;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.refreshDashboard();
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    compareStatsValues(currentVal, previousVal) {
      const result = {};
      let differenceDirection;
      let differencePercents;
      const differenceAmount = currentVal - previousVal;
      if (differenceAmount < 0) {
        differenceDirection = 'loss';
      } else if (differenceAmount > 0) {
        differenceDirection = 'grow';
      } else {
        differenceDirection = 'none';
      }
      if (previousVal === 0) {
        differencePercents = false;
      } else {
        differencePercents = 100 - (currentVal / previousVal) * 100;
      }
      // особый случай, чтобы в интерфейс выводился 0
      if (previousVal === 0 && currentVal === 0) {
        differencePercents = 0;
      }
      result.currentVal = currentVal;
      result.previousVal = previousVal;
      result.differenceDirection = differenceDirection;
      result.differenceAmount = differenceAmount;
      if (differencePercents === false) {
        result.differencePercents = false;
      } else {
        result.differencePercents = Math.abs(parseInt(differencePercents));
      }

      return result;
    },
    getContactEmail() {
      return helpers.getContactEmail();
    },
    calendarClick() {
      router.push('/portal/birthdays');
    },
    goToAdsNew() {
      router.push('/portal/ads');
    },
    goToAdsNewFromAdsBlock(event) {
      // console.log(event);
      if (event.target.tagName !== 'A') {
        router.push('/portal/ads');
      }
    },
    goToPortalAdsStatsFromAdsBlock(event) {
      // console.log(event);
      if (event.target.tagName !== 'A') {
        router.push('/portal/statistics-ads');
      }
    },
    goToPortalStatistics() {
      router.push('/portal/statistics');
    },
    goToPortalBirthdays(event) {
      // console.log(event);
      if (event.target.tagName !== 'SPAN') {
        router.push('/portal/birthdays');
      }
    },
    goToLocations(event) {
      // console.log(event);
      if (event.target.tagName !== 'A') {
        router.push('/settings/location');
      }
    },
    toggleShowMapInModal() {
      this.showMapInModal = !this.showMapInModal;
    },
    shuffleArr(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
    toggleBlockDisplaying(blockName) {
      if (this.marketerDashboardBlockDisplaying[blockName] !== undefined) {
        this.marketerDashboardBlockDisplaying[blockName] = !this.marketerDashboardBlockDisplaying[blockName];
        const { marketerDashboardBlockDisplaying } = this;
        const localStorageMarketerDashboardBlockDisplayingSettingsAsObject = JSON.parse(
          localStorage.getItem('marketerDashboardBlockDisplayingSettings')
        );
        localStorageMarketerDashboardBlockDisplayingSettingsAsObject[
          this.$store.state.userData.id
        ] = marketerDashboardBlockDisplaying;
        localStorage.setItem(
          'marketerDashboardBlockDisplayingSettings',
          JSON.stringify(localStorageMarketerDashboardBlockDisplayingSettingsAsObject)
        );
      }
    },
    // getConnectionsDataForMarketerDashboard(subtype) {
    //   subtype.forEach((el) => {
    //     portalStatsService.getConnectionsDataForPortalDashboard(this, 'pie', el);
    //     const piesOnly = ['all_visit', 'unique_visit', 'browser'];
    //     if (!piesOnly.includes(el)) {
    //       portalStatsService.getConnectionsDataForPortalDashboard(this, 'timeseries', el);
    //     }
    //   });
    // },
    getConnectionsDataForMarketerDashboard(subtype) {
      subtype.forEach((el) => {
        portalStatsService.getConnectionsDataForPortalDashboard(this, 'pie', el, true);
        const piesOnly = ['all_visit', 'unique_visit', 'browser'];
        if (!piesOnly.includes(el)) {
          portalStatsService.getConnectionsDataForPortalDashboard(this, 'timeseries', el, true);
        }
      });
    },
    getSMSCostForRange() {
      // portalStatsService.getSMSCostForRange(this);
      portalStatsService.getSMSCostForRange(this, true);
    },
    getSMSCostForPrevRange() {
      // portalStatsService.getSMSCostForPrevRange(this);
      portalStatsService.getSMSCostForPrevRange(this, true);
    },
    // getConnectionsDataForMarketerDashboardForForPreviousPeriod(subtype) {
    //   subtype.forEach((el) => {
    //     portalStatsService.getConnectionsDataForPortalDashboardForForPreviousPeriod(this, 'pie', el);
    //     const piesOnly = ['all_visit', 'unique_visit', 'browser'];
    //     if (!piesOnly.includes(el)) {
    //       portalStatsService.getConnectionsDataForPortalDashboardForForPreviousPeriod(this, 'timeseries', el);
    //     }
    //   });
    // },
    getConnectionsDataForMarketerDashboardForForPreviousPeriod(subtype) {
      subtype.forEach((el) => {
        portalStatsService.getConnectionsDataForPortalDashboardForForPreviousPeriod(this, 'pie', el, true);
        const piesOnly = ['all_visit', 'unique_visit', 'browser'];
        if (!piesOnly.includes(el)) {
          portalStatsService.getConnectionsDataForPortalDashboardForForPreviousPeriod(this, 'timeseries', el, true);
        }
      });
    },
    setTotalConnectionsForMarketerDashBoard(amount) {
      this.totalConnectionsForMarketerDashBoard = amount;
    },
    setUniqClientsForMarketerDashBoard(amount) {
      this.uniqClientsForMarketerDashBoard = amount;
    },
    setNewUsersForMarketerDashBoard(amount) {
      this.newUsersForMarketerDashBoard = amount;
    },
    setNotNewUsersForMarketerDashBoard(amount) {
      this.notNewUsersForMarketerDashBoard = amount;
    },
    setTotalConnectionsForMarketerDashBoardForPreviousPeriod(amount) {
      this.totalConnectionsForMarketerDashBoardForPreviousPeriod = amount;
    },
    setUniqClientsForMarketerDashBoardForPreviousPeriod(amount) {
      this.uniqClientsForMarketerDashBoardForPreviousPeriod = amount;
    },
    setNewUsersForMarketerDashBoardForPreviousPeriod(amount) {
      this.newUsersForMarketerDashBoardForPreviousPeriod = amount;
    },
    setNotNewUsersForMarketerDashBoardForPreviousPeriod(amount) {
      this.notNewUsersForMarketerDashBoardForPreviousPeriod = amount;
    },
    onLocationsMapMarkerClick(locationId) {
      this.$router.push({ name: 'Location', params: { locationId } });
    },
    getUserNameByID(id) {
      if (this.usersList && id) {
        const result = this.usersList.find((item) => item.id === id);
        if (result) {
          return result.username;
        }
        return '';
      }
      return '';
    },
    isRootLocations(id) {
      const rootLocation = this.$store.state.userData.base_location;
      return rootLocation === id;
    },
    dataInPercentages(data) {
      return (data * 100).toFixed(0);
    },
    memoryInGb(memory) {
      return (memory / 1073741824).toFixed(2);
    },
    memoryInMb(memory) {
      return (memory / 1048576).toFixed(0);
    },

    kbInMb(memory) {
      return (memory / 1024).toFixed(0);
    },

    cacheInKb(cache) {
      return (cache / 1024).toFixed(0);
    },
    secondsInDays(seconds) {
      const days = Math.floor(seconds / (3600 * 24));
      return `${days}`;
    },
    cpesName(id) {
      const cpesList = this.$store.state.cpesList || [];
      let cpeName;
      cpesList.forEach((cpe) => {
        if (cpe.id == id) {
          if (cpe.name == '') {
            cpeName = cpe.model.name;
          } else {
            cpeName = cpe.name;
          }
        }
      });
      return cpeName;
    },
    cpesModel(id) {
      const cpesList = this.$store.state.cpesList || [];
      let cpeModel;
      cpesList.forEach((cpe) => {
        if (cpe.id == id) {
          cpeModel = cpe.model.name;
        }
      });
      if (cpeModel) {
        if (cpeModel.length > 20) {
          cpeModel = cpeModel.substring(0, 20);
          cpeModel += '...';
        }
      }
      return cpeModel;
    },
    cutName(name) {
      return commonService.cutName(name);
    },
    getFormatMac(mac) {
      return helpers.getFormatMac(mac);
    }
  },
  filters: {
    momentForLocation(value) {
      if (value) {
        return moment(value).format('D MMM YYYY, H:mm');
      }
    },
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    },
    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    },
    cutLocationName(value) {
      if (value.length < 27) {
        return value;
      }
      return `${value.slice(0, 24)}...`;
    }
  },
  // created() {
  //   locationService.getLocations(this);
  //   usersService.getUsers(this, { onlyLoad: true });
  //   portalAdsService.getPortalAdsForMarketerDashboard(this);
  //   portalAdsService.getAdStatisticsForMarketerDashboard(this);
  //   portalAdsStatsService.getRangeForMarketerDashBoard(this);
  //   this.getConnectionsDataForMarketerDashboardForForPreviousPeriod([
  //     'all_visit',
  //     'unique_visit',
  //     'first_visit',
  //     'authen',
  //     'auth'
  //   ]);
  //   this.getConnectionsDataForMarketerDashboard(['all_visit', 'unique_visit', 'first_visit', 'authen', 'auth', 'os']);
  //   this.getSMSCostForRange();
  //   this.getSMSCostForPrevRange();
  // },

  created() {

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    this.getAll()
  },

  mounted() {
    // dashboardService.getSystemsInfo(this, dashboardService.setSystemInfo);
  }
};
</script>

<style lang="scss">
  .input-group-for-location-select-in-PortalDashborad--filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;
  }

  .select-location-in-PortalDashborad--filters .select__list-container {
    width: 400px;
  }
.row-flex {
  display: flex;
  justify-content: space-around;
}
.card-systemtext {
  font-size: 90%;
  line-height: 1.6;
}

.card-icon {
  min-height: 180px;
  padding: 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 75px 75px;
  background-position: 95% 80%;
}
.card-icon---system {
  background-image: url('/static/img/control-system-unit.svg');
}
.card-icon---cpu {
  background-image: url('/static/img/cpu-chip.svg');
}
.card-icon---ram {
  background-image: url('/static/img/ram.svg');
}
.card-icon---list {
  background-image: url('/static/img/list.svg');
}

.card-icon---load {
  background-image: url('/static/img/scales.svg');
}

.card-icon---support-block {
  /*background-image: url('/static/img/portal-dashboard/support-block.png');*/
}

.card-icon---connections-block {
  /*background-image: url('/static/img/portal-dashboard/connections-block.png');*/
}

.card-icon---authentications-block {
  /*background-image: url('/static/img/portal-dashboard/authentications-block.png');*/
}

.card-icon---authorizations-block {
  /*background-image: url('/static/img/portal-dashboard/authorizations-block.png');*/
}

.card-icon---poll-recomend-block {
  /*background-image: url('/static/img/portal-dashboard/poll-recomend-block.png');*/
}

.card-icon---locations-block {
  /*background-image: url('/static/img/portal-dashboard/locations-block.png');*/
}

.card-icon---os-block {
  /*background-image: url('/static/img/portal-dashboard/os-block.png');*/
}

.card-icon---ads-block {
  /*background-image: url('/static/img/portal-dashboard/ads-block.png');*/
}

.card-icon---poll-quality-recomend-block {
  /*background-image: url('/static/img/portal-dashboard/poll-quality-recomend-block.png');*/
}

.card-icon---birthdays-block {
  /*background-image: url('/static/img/portal-dashboard/birthdays-block.png')*/
}

.support-block {
  font-size: 1.35em;
}

.card-system {
  display: flex;
}
.card-systeminfo {
  padding-right: 1rem;
}
.dashboard {
  // min-width: 730px;
}

.card.card-new {
  border-top: 4px solid #81c784;
}

.card.card-returned {
  border-top: 4px solid rgba(255, 143, 7, 0.8);
}

.card.card-unique {
  border-top: 4px solid rgb(124, 181, 236);
}

.card.card-default {
  border-top: 4px solid rgba(183, 183, 183, 0.5);
}
.clickable {
  cursor: pointer;
  user-select: none;
}

.dashboard-title {
  font-size: 1.1rem;
}

.container-dashboard {
  display: flex;
  height: 100vh;
}
.container-dashboard .loader {
  background: rgba(0, 0, 0, 0);
}

.dashboar-icon {
  width: 140px;
}

.no-data-block {
  opacity: 0.6;
}

.no-data {
  width: 100%;
  text-align: center;
}

.cpu-hc {
  height: 140px;
}
.cpe-hc {
  height: 140px;
}

.client-mac:after {
  content: 'mac address';
  color: #bcc5d0 !important;
  font-size: 45%;
  top: 20px;
  left: 16px;
  position: absolute;
}

.cpe-icon {
  vertical-align: baseline;
  height: 15px;
}

.card-portal-dashboard-recomendation-birthdays .card-footer {
  background-color: transparent !important;
}

.portal-dashboard-calendar .card {
  border-width: 0px;
  background-color: transparent !important;
}

.portal-dashboard-calendar .datepicker {
  /*font-size: 14px;*/
}

.portal-dashboard-calendar .datepicker-dateRange {
}

.portal-dashboard-calendar .datepicker-body span {
  /*width: 28px;*/
  /*  height: 28px;*/
}

.portal-dashboard-calendar .datepicker-weekRange span {
  /*width: 28px;*/
  /*height: 28px;*/
}

.portal-dashboard-calendar .datepicker-inner {
  position: relative;
  top: -30px;
}
.portal-dashboard-calendar .datepicker-ctrl {
  z-index: 3;
}

.portal-dashboard-calendar .day-cell {
  height: 24px !important;
}
.portal-dashboard-calendar .datepicker-dateRange-item-active {
  height: 30px !important;
}

.portal-dashboard-calendar {
  top: -48px;
  position: relative;
  background-color: transparent;
}

.portal-dashboard-calendar .datepicker-popup {
  background-color: transparent !important;
}

.card-portal-dashboard .card-footer {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
.dashboard-row-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.cards-fix {
  flex-direction: column;
}

.loader-dashboard:after {
  position: absolute;
  top: calc(50vh - 10%);
}

.loader-backdrop-dashboard-for-marketer {
  width: 100%;
  position: absolute;
}

.add-padding {
  padding-left: 1em;
}

.dashboard-total {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-total-header {
  font-size: 2em;
  text-align: center;
}

.dashboard-total-text {
  font-size: 4em;
}

.category-profit {
  flex-grow: 1;
  margin-top: 20px;
}

.width_full {
  width: 100%;
}

.one-line-text {
  white-space: nowrap;
}

.portal-dashboard-card-caption {
  margin-top: -1.3em;
  margin-bottom: 1em;
}

.in-card-portal-dashboard {
  color: inherit;
}

.ul-in-card-portal-dashboard {
  padding-left: 2em;
}
.li-in-card-portal-dashboard a {
  white-space: normal;
}

.card-portal-dashboard-wrapper {
  /* width: 350px !important; */
  /* min-width: 295px !important; */
  overflow: hidden;
}

/*.loader-wrapper {*/
/*  position: relative;*/
/*}*/

.loader-spinner-wrapper {
  position: relative;
}

.card-portal-dashboard-wrapper-hidden {
  /*хак чтобы последняя строчка flex была выравнена по левому краю, вместо justify-content-around*/
  visibility: hidden;
}

.card-portal-dashboard-inner-wrapper {
  min-height: 170px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*position: relative;*/
  /*left: 30px;*/
  clear: right;
}
.card-portal-dashboard-inner-wrapper-for-ads-views {
  min-height: 130px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*position: relative;*/
  /*left: 30px;*/
  clear: right;
}
.card-portal-dashboard-inner-wrapper-for-ads {
  min-height: 130px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*position: relative;*/
  /*left: 30px;*/
  clear: right;
}

.portal-dashboard-calendar-wrapper {
  overflow: auto;
}

.card-portal-dashboard {
  min-height: 360px !important;
  word-wrap: break-word !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.card-icon-portal-dashboard {
  padding: 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 60px 60px;
  background-position: 96% 4%;
}

.card-portal-dashboard-recomendation-birthdays {
  background-image: url('/static/img/portal-dashboard/birtday-recomend-block-square.jpg');
  color: black !important;
  background-size: cover;
}

.card-portal-dashboard-recomendation-birthdays h4 {
  color: white !important;
}

.card-portal-dashboard-recomendation-birthdays .card-recommendation {
  background-color: rgba(255, 255, 255, 0.67);
}

.img-in-card---os-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}

.img-in-card---connections-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}

.img-in-card---authentications-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}

.img-in-card---authorizations-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}

.img-in-card---poll-recomend-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}

.img-in-card---locations-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}

.img-in-card---ads-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}

.img-in-card---poll-quality-recomend-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}

.img-in-card---birthdays-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}

.img-in-card---support-block {
  height: 60px;
  width: 60px;
  position: relative;
  top: -8px;
  right: -8px;
}
.portal-dashboard-card-content {
  font-size: 1.2em;
}
.portal-dashboard-card-content-digits {
  font-size: 1.25em;
  line-height: 0.8em;
}
.custom-margin-top-for-no-data-in-ads-views {
  margin-top: -1.5em;
}
.portal-dashboard-digit-accent-connections-digit {
  font-size: 2.5em;
}
.portal-dashboard-digit-accent-connections-wrapper {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portal-dashboard-digit-accent-connections-caption {
  text-align: center;
  font-size: 1em !important;
  position: relative;
  top: -0.5em;
}
.portal-dashboard-digit-accent-digit {
  font-size: 2.2em;
}
.portal-dashboard-digit-accent-digit-small {
  font-size: 0.77em;
}
.portal-dashboard-digit-accent-digit div {
  padding-left: 3px;
}
.portal-dashboard-digit-accent-connections-digit div {
  padding-left: 3px;
}
.portal-dashboard-digit-accent-wrapper {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portal-dashboard-digit-accent-caption {
  text-align: center;
  font-size: 0.8em !important;
  position: relative;
  top: -0.5em;
}
.portal-dashboard-digit-accent-wrapper-and-more {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.portal-dashboard-digit-accent-caption-and-more {
  text-align: center;
  font-size: 0.8em !important;
  position: relative;
  top: -0.5em;
}
.portal-dashboard-digit-accent-wrapper-hidden {
  visibility: hidden;
  width: 100px;
}
.portal-dashboard-digit-accent-locations-when-manager-digit {
  font-size: 3em;
}
.portal-dashboard-digit-accent-locations-when-manager-wrapper {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portal-dashboard-digit-accent-locations-when-manager-caption {
  text-align: center;
  font-size: 1em !important;
  position: relative;
  top: -0.5em;
}
.locations-when-manager-list {
  overflow: hidden;
}
.locations-when-manager-left-block {
  width: 100px;
}
.locations-when-manager-right-block {
  min-width: 200px;
}
.numbers-difference-for-smsCost {
  font-size: 1.3em;
}
</style>
